import upperFirst                        from 'lodash/upperFirst'
import camelCase                         from 'lodash/camelCase'
import { Turbo }                         from '@hotwired/turbo-rails'
import ApplicationController             from '../support/application_controller'
import CreateChannel                     from '../client/cable'
import $fq                               from '../support/fake_query'
import { disableElement, enableElement } from '../support/helpers'

const noteTargets = [
  'AttendanceCertificate',
  'ClinicalPhotos',
  'ConsultationNote',
  'DischemHealthAssessment',
  'DiscoveryLifeAssessment',
  'DocumentsDoctor',
  'DocumentsGynaecologist',
  'DocumentsNurse',
  'FamilyResponsibilityLetterDoctor',
  'FamilyResponsibilityLetterGynaecologist',
  'FamilyResponsibilityLetterNurse',
  'GeneralNote',
  'GynaecologyAssessment',
  'ICD10CodesDoctor',
  'ICD10CodesGynaecologist',
  'ICD10CodesNurse',
  'IWyzeAssessment',
  'MedicalHistory',
  'MedicalRecord',
  'MedicationAndInjectable',
  'MedihelpAssessment',
  'MultiplyWellnessAssessment',
  'OldMutualAssessment',
  'OneLifeAssessment',
  'OutsuranceWellnessAssessment',
  'PrescriptionDoctor',
  'PrescriptionGynaecologist',
  'PrescriptionNurse',
  'PatientConsent',
  'RecommendationDoctor',
  'RecommendationNurse',
  'ReferralLetter',
  'ReferralLetterDoctor',
  'ReferralLetterGynaecologist',
  'ReferralLetterNurse',
  'SickNote',
  'Vaccines',
  'VitalityHealthCheck',
  'Vitals'
]

export default class extends ApplicationController {

  static targets = [
    'cashStart',
    'disableDuringCall',
    'emailButton',
    'footer',
    'formSubmit',
    'frameConsultationInframe',
    'inframeBody',
    'manageVaccines',
    'noteTitle',
    'patientName',
    'responseBack',
    'responseBody',
    'responseBodyActive',
    'scrollbars',
    'videomedDisable',
    // Note blocks
    'doctorNotes',
    'doctorNotesSB',
    'hcpNotes',
    'hcpNotesSB',
    'vitalConsultationNote',
    // Tabs
    'tabAssessments',
    'tabConsultDetails',
    'tabDocuments',
    // Templates
    'backToConsultationTempl',
    'consultationActionsTempl',
    'dropdown',
    'kaeloDropdownItem',
    'noteActionsTempl',
    'schemeConsult',
    'schemeDropdownItem',
    'schemeStart',
    'voucherDropdownItem'
  ].concat(noteTargets.map((i) => `note${i}`), noteTargets.map((i) => `menu${i}`))

  initialize() {
    this.createConsultationChannel()
  }

  connect() {
    this.autoLoadForm()
  }

  static values = {
    consultationId: String,
    userId:         String
  }

  static classes = []

  static outlets = [
    'videomed-call',
    'consultations--documents'
  ]

  // ==== Actions

  toggleLoadingSpinner(event) {
    event.currentTarget.classList.toggle('-loading')
  }

  showFrameInFrame(event) {
    const [_response, _status, xhr] = event.detail

    this.responseBodyTarget.innerHTML = xhr.response
    this.responseBodyTarget.classList.remove('u-hide')

    this.switchActions()
    this.hideElements()
    this.scrollToTop()
  }

  showFrame(event) {
    const [_response, _status, xhr] = event.detail

    this.responseBodyTarget.innerHTML = xhr.response
    this.responseBodyTarget.classList.remove('u-hide')

    this.hideElements()
    this.addInframe()
    this.switchActions()
    this.scrollToTop()
  }

  cancelMedicalHistoryInactiveConsultation(event) {
    const [_response, _status, xhr] = event.detail

    this.responseBodyTarget.innerHTML = xhr.response
    this.showElements()
    this.restoreActions()
    this.scrollToTop()
  }

  ajaxError(event) {
    const [_response, _status, xhr] = event.detail

    this.renderFlashMessage(xhr)
  }

  ajaxSuccess(event) {
    const [_response, _status, xhr] = event.detail

    if (xhr.getResponseHeader('content-type').includes('text/vnd.turbo-stream.html')) {
      Turbo.renderStreamMessage(xhr.response)
      return
    }

    if (event.srcElement.hash) {
      window.history.pushState({ turbo: true }, null, event.srcElement.hash)
    }

    this.responseBodyTarget.innerHTML = xhr.response
    this.responseBodyTarget.classList.add(event.srcElement.dataset.noteClass)

    this.hideElements()
    this.addInframe()
    this.switchActions()
    this.scrollToTop()
  }

  closeInframe() {
    if (this.hasEditPatientBackTarget) {
      window.location.reload()
    } else {
      window.history.pushState({ turbo: true }, null, ' ')

      this.clearResponseBody()
      this.frameConsultationInframeTarget.innerHTML = ''
      if (this.frameBackToConsultation) this.frameBackToConsultation.innerHTML = ''

      this.showElements()
      this.removeInframe()
      this.restoreActions()
      this.scrollToTop()
    }
  }

  clearResponseBody() {
    this.responseBodyTarget.innerHTML = ''
    this.responseBodyTarget.className = ''
  }

  closeInframeDelayed() {
    const self = this

    setTimeout(() => {
      self.closeInframe()
    }, 1200)
  }

  formSubmit() {
    if (this.hasFormSubmitTarget) {
      this.formSubmitTarget.click()
    }
  }

  showConsultButton() {
    if (this.canUseSchemeOrVoucher()) {
      this.enableSchemeButton()
      if (this.canClaimFromScheme()) {
        if (this.canUseKaelo()) {
          this.disableSchemeDropdownItem()
          this.enableKaeloDropdownItem()
        } else {
          this.disableKaeloDropdownItem()
          this.enableSchemeDropdownItem()
        }
      } else {
        this.disableKaeloDropdownItem()
        this.disableSchemeDropdownItem()
      }
    } else {
      this.enableCashButton()
    }
  }

  showDocumentEmailForm() {
    this.consultationsDocumentOutlet.showInframeEmailForm()
    this.emailButtonTarget.remove()
  }

  scrollToTop() {
    this.scrollbarsTarget.scrollTop = 0
  }

  // ==== Getters

  get urlFragment() {
    return window.location.hash.substr(1)
  }

  get frameBackToConsultation() {
    return document.getElementById('frameBackToConsultation')
  }

  // ==== Setters

  // ==== Private

  canClaimFromScheme() {
    return this.hasSchemeConsultTarget && this.schemeConsultTarget.dataset.scheme === 'true'
  }

  canUseVoucher() {
    return this.hasVoucherDropdownItemTarget
  }

  canUseKaelo() {
    return this.hasKaeloDropdownItemTarget && this.schemeConsultTarget.dataset.kaelo === 'true'
  }

  canUseSchemeOrVoucher() {
    return this.canUseVoucher() || this.canClaimFromScheme()
  }

  enableSchemeButton() {
    if (this.hasSchemeStartTarget) {
      $fq(this.schemeStartTarget).show()
    }
    if (this.hasCashStartTarget) {
      $fq(this.cashStartTarget).hide()
    }
  }

  enableCashButton() {
    if (this.hasSchemeStartTarget) {
      $fq(this.schemeStartTarget).hide()
    }
    if (this.hasCashStartTarget) {
      $fq(this.cashStartTarget).show()
    }
  }

  enableSchemeDropdownItem() {
    if (this.hasSchemeDropdownItemTarget) {
      $fq(this.schemeDropdownItemTarget).show()
    }
  }

  disableSchemeDropdownItem() {
    if (this.hasSchemeDropdownItemTarget) {
      $fq(this.schemeDropdownItemTarget).hide()
    }
  }

  enableKaeloDropdownItem() {
    if (this.hasKaeloDropdownItemTarget) {
      $fq(this.kaeloDropdownItemTarget).show()
    }
  }

  disableKaeloDropdownItem() {
    if (this.hasKaeloDropdownItemTarget) {
      $fq(this.kaeloDropdownItemTarget).hide()
    }
  }

  autoLoadForm() {
    let button

    if (this.urlFragment.match(/!@/)) {
      button = document.querySelector(`[data-consultation-target="${camelCase(this.urlFragment)}"]`)
    } else {
      button = document.querySelector(`[data-consultation-target="${camelCase(this.urlFragment)}"] a`)
    }

    if (button) {
      button.click()
    }
  }

  hideElements() {
    this.responseBodyActiveTargets.forEach((element) => {
      element.classList.add('u-hide')
    })
  }

  disableElements() {
    this.disableDuringCallTargets.forEach((el) => disableElement(el))
  }

  enableElements() {
    this.disableDuringCallTargets.forEach((el) => enableElement(el))
  }

  showElements() {
    this.responseBodyActiveTargets.forEach((element) => {
      element.classList.remove('u-hide')
    })
  }

  addInframe() {
    this.inframeBodyTarget.classList.add('-inverse', 'l-inframe-viewport')

    if (this.hasHcpNotesSBTarget) {
      this.hcpNotesSBTarget.querySelectorAll('.m-card').forEach((element) => {
        element.classList.add('-inverse')
      })
    }
  }

  removeInframe() {
    this.inframeBodyTarget.classList.remove('-inverse', 'l-inframe-viewport')

    if (this.hasHcpNotesSBTarget) {
      this.hcpNotesSBTarget.querySelectorAll('.m-card').forEach((element) => {
        element.classList.remove('-inverse')
      })
    }
  }

  switchActions() {
    if (this.hasBackToConsultationTemplTarget && this.hasResponseBackTarget) {
      const backToConsultation = document.importNode(this.backToConsultationTemplTarget.content, true)

      this.responseBackTarget.innerHTML = ''
      this.responseBackTarget.appendChild(backToConsultation)
    }

    if (this.hasPatientNameTarget) {
      this.patientNameTarget.classList.remove('u-hide')
    }

    if (this.hasVideomedCallOutlet && this.videomedCallOutlet.videomedCallStatus === 'answered') {
      this.disableElements()
    }
  }

  restoreActions() {
    if (this.hasResponseBackTarget) {
      this.responseBackTarget.innerHTML = ''
    }

    if (this.hasPatientNameTarget) {
      this.patientNameTarget.classList.add('u-hide')
    }

    if (this.hasVideomedCallOutlet && this.videomedCallOutlet.videomedCallStatus === 'answered') {
      this.disableElements()
    }
  }

  // ==== Channels

  createConsultationChannel = async () => {
    const self = this

    if (!self.consultationIdValue) return

    await CreateChannel({
      channel:         'ConsultationNoteChannel',
      user_id:         self.userIdValue,
      consultation_id: self.consultationIdValue
    }, {
      received(data) {
        const tabTargetStr     = `${data.resource_tab.name}Target`
        const hasTabTargetStr  = `has${upperFirst(tabTargetStr)}`
        const targetStr        = `${data.note_type}Target`
        const hasTargetStr     = `has${upperFirst(targetStr)}`
        const menuTargetStr    = `menu${upperFirst(targetStr).replace('Note', '')}`
        const hasMenuTargetStr = `hasMenu${upperFirst(targetStr).replace('Note', '')}`

        // Update tab counter
        if (self[hasTabTargetStr]) {
          const counter = self[tabTargetStr].querySelector('.-item-tab__counter')

          counter.classList.toggle('u-hide', data.resource_tab.count === 0)
          counter.textContent = data.resource_tab.count
        }

        // Remove existing note
        if (self[hasTargetStr]) {
          self[targetStr].remove()
        }

        // Update note check status
        if (self[hasMenuTargetStr]) {
          self[menuTargetStr].outerHTML = data.resource_link
        }

        // Add new / updated note
        if (data.resource_html) {
          switch (data.type) {
            case 'renderV2_attendance_certificate':
            case 'renderV2_clinical_photos':
            case 'renderV2_dischem_health_assessment':
            case 'renderV2_discovery_life_assessment':
            case 'renderV2_documents':
            case 'renderV2_family_responsibility_letter':
            case 'renderV2_general_note':
            case 'renderV2_gynaecology_assessment':
            case 'renderV2_icd10_codes':
            case 'renderV2_i_wyze_assessment':
            case 'renderV2_medical_history':
            case 'renderV2_medication_and_injectable':
            case 'renderV2_medihelp_assessment':
            case 'renderV2_multiply_wellness_assessment':
            case 'renderV2_old_mutual_assessment':
            case 'renderV2_one_life_assessment':
            case 'renderV2_outsurance_wellness_assessment':
            case 'renderV2_prescription':
            case 'renderV2_patient_consent':
            case 'renderV2_recommendation':
            case 'renderV2_referral_letter':
            case 'renderV2_sick_note':
            case 'renderV2_vaccines':
            case 'renderV2_vitality_health_check':
            case 'renderV2_vitals':
              this.renderNoteCard(data.resource_html, data.render_target)
              break

            default:
              break
          }

          if (self[hasTargetStr]) {
            this.targetUpdated(self[targetStr])
          }
        }
      },

      renderNoteCard(html, target) {
        const targetStr    = `${target}Target`
        const hasTargetStr = `has${upperFirst(targetStr)}`
        const fragment     = document.createRange().createContextualFragment(html)
        const existingNote = document.querySelector(`[data-dnsb-vmc-id="${fragment.firstChild.dataset.dnsbVmcId}"]`)

        if (self[hasTargetStr]) {
          if (target === 'doctorNotesSB' && existingNote) {
            existingNote.outerHTML = html
          } else {
            self[targetStr].insertAdjacentHTML('afterbegin', html)
          }
        }
      },

      targetUpdated(element) {
        if (element) {
          element.classList.add('-ping')
          setTimeout(() => { element.classList.remove('-ping') }, 3000)
        }
      }

    })
  }

}
