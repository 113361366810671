import ApplicationController from '../../support/application_controller'
import WizardMixin           from '../../support/wizard_mixin'

export default class extends WizardMixin(ApplicationController) {

  static targets = [
    'ingredient',
    'prescription',
    'referralLetter'
  ]

  static classes = [
    'hide'
  ]

  togglePrescription({ currentTarget: { checked }, params: { prescription } }) {
    this.prescriptionTargets
      .find((target) => target.dataset.prescription === prescription)
      ?.classList
      ?.toggle(this.hideClass, !checked)
  }

  toggleIngredient({ currentTarget: { value }, params: { prescription } }) {
    this.ingredientTargets
      .filter((target) => target.dataset.prescription === prescription)
      .forEach((target) => {
        target.classList.toggle(this.hideClass, target.dataset.ingredient !== value)
      })
  }

  toggleReferralLetter(event) {
    if (event.currentTarget.value === 'pathology_referral_letter') {
      this.showRadioButtonMoreDetails({
        target: this.referralLetterTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.referralLetterTarget
      })
    }
  }

}
